export const SliderData = [
    {
      image:
      'NEW1.jpg'
    },
    {
      image:
        'NEW2.JPG' 
    }, 
    {
      image:
        'NEW3.JPG' 
    }, 
    {
      image:
        'TGNew2.jpeg'
    }
  ];