import React from 'react'; 
import './Art-Therapy.css'; 

import '../../Button.css'; 

function ArtTherapyMandarin() { 
  window.scrollTo(0, 0);
    return (
      <>
           
          <div className="Art-Therapy">

          <form method="get" action="#/art-therapy"> 
            <button id="the-button" type='btn'>English</button> 
          </form> 

            <h1 className="exhibition">艺术疗法</h1> 
            <hr /> 

            <h5 id="caption">
            2016年4月29日–5月27日  
            </h5> 

            <img id="image-thing-at" src="AT1.jpeg" /> 

            <h5 id="caption">
            艺术家：蔡江 
            </h5> 
            <h5 id="caption">
            策展人：刘薇（Lena Liu）
            </h5> 
            <h5 id="caption">
            学术支持：Juan Punt 与纽约 WhiteBox 画廊合作 
            </h5> 
            <h5 id="caption">
            开幕式：2016年4月29日； 3:00 PM。
            </h5> 
            <h5 id="caption">
            主办方：美国亚洲文化传媒集团，
            红色艺术博物馆。 2楼 
            </h5> 

            <p id="art-paragraph-other">
            来自纽约-- 蔡江 JON TSOI盲目意述家-37年后的故土 关于“疗愈与艺术” 初次⻅到蔡江作品是2015年，当时感觉怪怪的，蒙着眼睛创作... 
            的经历，他做艺术的同时还是一名中医师，1979年去美国当代艺术圣之后看到他很多作品有了新的认识，蔡江在介绍他作品同时也谈到他 地纽约，
            37年浪子的身心和灵魂的疲惫，他潜心研究用中医阴阳的气韵， 感。蒙上眼睛，没有外在的压力和干扰，发自内心的对故土的思念和情  
            </p>
            <p id="art-paragraph-other">
            到天地之间，山水之间， 切割画布感受到气息的瞬间通透，阳 光，空气充斥人的内心。阳气普照大地， 视觉是愉悦的 ，艺术就是 为了给人带来愉悦感。
            保护好这种愉悦 并捆绑画布，添加令你愉悦的 色彩...     中医认为人体本来是一个阴阳协调的整体，失去阴阳平衡，失去 视觉的愉悦，
            身心就会疲惫...蔡江惊喜的发现他的阴阳理论结合当代 艺术创作，疗愈了他漂泊和疲惫的内心 ，同时这种阴阳调和表现在当 代艺术创作中 ，他是唯一的创作者，很多收藏购买者因此更加喜爱他 的作品。身心、家庭，社会、唯有和谐，阳光普照，这个世界会无限 美好。
            </p> 
            <p id="art-paragraph-other">
            疗愈艺术   这些与众不同的作品有着与前卫艺术前驱卢⻄奥·丰塔纳 (Lucio Fontana)(其独特的割破画布的艺术⻛格创造出一种深邃与空间的神秘   
            感，赋予作品挑衅却亦优雅的感觉)或具体艺术协会大师村上三郎 (Murakami Saburo)(其对于平面材料的对抗，以身体穿过一系列纸屏留   
            下一系列破碎的硬板纸，以汲取一种新的建筑形态)并驾⻬驱的影响。 蔡江的创作方法论，严谨的遵循中国道家哲学的衍生和最为直接的途 径。
            最终的作品中那无法预知的审美，通过蒙住双眼，运用意识进行 创作，凝结呈现成一瞬间的力与美。   整个行为艺术包含的三部曲中的第一章开始于
            一面两米方形三层 画布的后方。蔡江，艺术家萨满，蒙着双眼在整个画布表面来回穿 刺，每个手势都充满张力，每个移动都精心设计，并与其意识相连 
            结。通过行为，延展出一片广阔的空际和后续动作的流动空间:一种 无法遇⻅的，痛苦纠结的行为与大自然的天然构造相呼应(如打雷，暴 
            雨亦或飓⻛来袭的强烈疯狂)。通过蒙眼，艺术家创造了无线自由超越 障碍的空间，摆脱肉眼的关注焦点，清空思想和意识。 
            第二章融入了绳子的编织和飘荡，以及不同尺寸的麻线，去修补 原有的同质，静态的平面形式。这个行为象征了一种对精神，心灵以 及身体的疗愈，
            达到意想不到的全新平衡。(的确，对蔡江而言，艺术  创作在于运用有意的行为进行解构，从无到有，创造全新自由的平 衡。) 
            </p> 
            <p id="art-paragraph-other">
            这两种行为，看似是依赖于超现实主义和达达主义的宗旨上，却 因其缺乏决断，选择和机遇而有所不同。每个步骤都是下意识的设计 和重组，
            因此形成的结果亦是随机的。   第三章和最后步骤是由艺术家萨满面前的观看者完成的，当他摘 下眼罩后，会指引观看者挑选事先混合好的颜料中的一种，
            每种颜色 代表了一种心境和状态。(譬如，蓝色代表阴的力量，红色代表阳的力 量，⻩色代表中性等等)。当观众挑选了属于自己的颜色，他们需要蒙 
            上双眼，一只手拿着颜料罐，将颜料挥洒成一道洪流，形成天然的作 品呈象。完成作品的震撼力可以与艺术家安德烈.⻢松(Andre Masson) 
            或杰克逊.波洛克(Jackson Pollock)的杰作相媲美，亦或是来自澳大利 亚土著巫师的画作。 蔡江的“体验”可以被视为一件震撼有力的艺术作品，
            亦或是一场居 高临下威⻛庄严的疗愈仪式。“行为画作”因其力量和影响，在人的记 忆和意识形态中保留⻓久的体验。拘泥或跳脱于艺术创作的形式，
            行 为画作赋予我们一种全新观看和疗愈的方式，让物质本身与人类的自 我精神因其而相遇与交汇。 
            </p> 
            <p id="art-paragraph-other">
            艺术家介绍   蔡江Jon Tsoi，原籍中国四川。1979年去美国当代艺术圣地纽 约，37年不断耕耘研究和体悟中国哲学的超感官，超经验，超时空的  
            “道德经”。结合中国医学望闻问切，阴阳气血，身体，精神，灵魂的 “皇帝内经”。 ·从1981起在美国年起举办个人画展30多次，参加联展20多次。 
            ·由美国6家画廊，及一家英国画廊，在中国LenaLiu经纪代理和销 售作品。   ·多次参加世界各地的艺博会。2014年在美国纽约皇后博物馆4天 
            盲目行为艺术表演，“艺术诊断和对艺术的诊断”。 
            </p> 

          </div>
        </> 
    );
  }
  
  export default ArtTherapyMandarin; 